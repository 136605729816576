import React from 'react';

const ActionButton = ({ icon, extraClass, tooltip, onClick, styles }) => {
    return (
        <a
            style={styles}
            title={ tooltip }
            onClick={ onClick }
            className={extraClass}
        >
            <i className="material-icons">{ icon }</i>
        </a>
    );
};

export default ActionButton;