import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {

    const { t } = useTranslation();

    return (
        
        <footer className="page-footer footer footer-static footer-dark gradient-45deg-light-blue-cyan gradient-shadow navbar-border navbar-shadow">
            <div className="footer-copyright">
                <div className="container">
                    <span>&copy; {(new Date()).getFullYear()} <a href="https://www.jardm.com/" target="_blank" rel="noopener noreferrer">jardm</a> { t('footer_allrights') }.</span>
                </div>
            </div>
        </footer>
    );

};

export default Footer;