import TYPES from './types';
import { apiClientWithoutToken, apiClientWithToken } from '../services/backend';

export const startLogin = (username, password) => {
    return async(dispatch) => {
        try {
            const res = await apiClientWithoutToken().post(
                '/users/login', 
                { username, password }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {

                const { token, user } = data;

                localStorage.setItem('token', token);
                localStorage.setItem('token-init-date', new Date().getTime());
                //localStorage.setItem('user', user);

                dispatch(login(user));

                return { ok: true, message: data.message };

            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const startChecking = () => {
    return async (dispatch) => {

        try {

            const res = await apiClientWithToken().get('/users/renew');

            const { ok, result: { data } } = res.data;

            if (ok) {

                const { token, user } = data;

                localStorage.setItem('token', token);
                localStorage.setItem('token-init-date', new Date().getTime());

                dispatch(login(user));

                return { ok: true, message: data.message };

            } else {
                dispatch(checkingFinish());
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            dispatch(checkingFinish());
            return { ok: false, message: 'message-server-error' };
        }

    };
};

export const startLogout = () => {
    return (dispatch) => {
        localStorage.removeItem('token');
        localStorage.removeItem('token-init-date');

        dispatch(logout());
    };
};

const logout = () => ({
    type: TYPES.AUTH_LOGOUT
});

const login = (user) => ({
    type: TYPES.AUTH_LOGIN,
    payload: user
});

const checkingFinish = () => ({
    type: TYPES.AUTH_CHECKED
});

export const changePassword = (user) => {
    return async (dispatch) => {
        try {
            const res = await apiClientWithToken().post(
                '/users/changePassword', user
            );
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }      
    };
};

export const forgetPassword = (user) => {
    return async (dispatch) => {
        try {
            const res = await apiClientWithoutToken().post(
                '/users/forgetPassword', user
            );
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }      
    };
};

export const resetPassword = (user) => {
    return async (dispatch) => {
        try {
            const res = await apiClientWithoutToken().post(
                '/users/resetPassword', user
            );
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }      
    };
};

export const storeClean = () => ({
    type: TYPES.STORE_CLEAN
});
