import React, { useCallback, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formValueSelector } from "redux-form";
import { useHistory } from "react-router-dom";

import Can from "../../routes/Can";

import {
  showError,
  showConfirmation,
  showSuccess,
} from "../../helpers/messages";

import UserSearchForm from "./SearchForm";
import PageTitle from "../../components/UI/PageTitle";
import PageContainer from "../../components/UI/PageContainer";
import ContentContainer from "../../components/UI/ContentContainer";
import Pagination from "../../components/UI/Pagination";
import ExcelButton from "../../components/UI/ExcelButton";
import Spacer from "../../components/UI/Spacer";
import ActionButton from "../../components/UI/ActionButton";
import SortingHeader from "../../components/UI/SortingHeader";
import FloatingButtonAction from "../../components/UI/FloatingButtonAction";

import {
  fetchUsers,
  downloadExcel,
  selectUser,
  deleteUser,
} from "../../actions/user";
import { startLoading, endLoading } from "../../actions/ui";

const ScreensUserList = ({ username, active, email, role_id }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { list, paging, filter } = useSelector((state) => state.user);
  const { sort, direction } = paging;

  const history = useHistory();

  const fetchData = useCallback(
    (options) => {
      dispatch(startLoading());
      dispatch(fetchUsers(options)).then((res) => {
        dispatch(endLoading());
        if (!res.ok) {
          showError(t("message-title-error"), t(res.message));
        }
      });
    },
    [dispatch, t]
  );

  useEffect(() => {
    fetchData({ ...paging, ...filter });
  }, [dispatch, filter, fetchData]);

  useEffect(() => {
    dispatch(selectUser(null));
  }, [dispatch]);

  const handlePaginate = (index) => {
    fetchData({ ...paging, ...filter, page: index });
  };

  const handleSort = (name) => {
    let newDirection = "asc";
    if (sort === name) {
      newDirection = direction === "asc" ? "desc" : "asc";
    }
    fetchData({ ...paging, ...filter, sort: name, direction: newDirection });
  };

  const handleEdit = (item) => {
    dispatch(selectUser(item));
    history.push("/usuarios/formulario");
  };

  const handleDelete = (id, name) => {
    showConfirmation(
      t("message-title-confirmation"),
      t("message-delete-user-text", { name }),
      t("button-title-delete"),
      t("button-title-cancel"),
      () => {
        dispatch(startLoading());
        dispatch(deleteUser(id)).then((res) => {
          dispatch(endLoading());
          if (res.ok) {
            showSuccess(t("message-title-success"), t(res.message));
            fetchData({ ...paging, ...filter, page: 1 });
          } else {
            showError(t("message-title-error"), t(res.message));
          }
        });
      }
    );
  };

  const handleExcel = () => {
    dispatch(startLoading());
    dispatch(
      downloadExcel({
        username: username,
        active: active,
        email: email,
        role_id: role_id,
      })
    ).then((res) => {
      dispatch(endLoading());
      if (!res.ok) {
        showError(t("message-title-error"), t(res.message));
      }
    });
  };

  const handleAdd = () => {
    dispatch(selectUser(null));
    history.push("/usuarios/formulario");
  };

  const renderTable = () => {
    return (
      <table className="dataTable striped highlight responsive-table">
        <thead>
          <tr>
            <SortingHeader
              title={t("screens-user-list-username")}
              direction={sort === "username" ? direction : ""}
              onClick={() => handleSort("username")}
            />
            <SortingHeader
              title={t("screens-user-list-email")}
              direction={sort === "email" ? direction : ""}
              onClick={() => handleSort("email")}
            />
            <th>{t("screens-user-list-role")}</th>
            <th>{t("screens-user-list-active")}</th>
            <th>{t("screens-list-acciones")}</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item) => (
            <tr key={item.id}>
              <td>{item.username}</td>
              <td>{item.email}</td>
              <td>
                {item.roles.map((role, index) => {
                  return index === 0
                    ? role.description
                    : `, ${role.description}`;
                })}
              </td>
              <td>
                {item.active ? (
                  <span className="chip green lighten-5">
                    <span className="green-text">{t("Activo")}</span>
                  </span>
                ) : (
                  <span className="chip red lighten-5">
                    <span className="red-text">{t("Desactivado")}</span>
                  </span>                  
                )}
              </td>
              <td>
                <Can
                  perform="Users:edit"
                  yes={() => (
                    <ActionButton
                      icon="edit"
                      tooltip={t("screens-list-acciones-edit")}
                      extraClass="btn-floating btn-small waves-effect waves-light green darken-3 ml-2"
                      style = {{cursor:'pointer'}}                      
                      onClick={() => handleEdit(item)}
                    />
                  )}
                />
                <Can
                  perform="Users:delete"
                  yes={() => (
                    <ActionButton
                      icon="delete_forever"
                      tooltip={t("screens-list-acciones-delete")}
                      extraClass="btn-floating btn-small waves-effect waves-light red darken-3 ml-2"
                      styles={{cursor:'pointer'}}
                      onClick={() => handleDelete(item.id, `${item.username}`)}
                    />
                  )}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <PageContainer>
      <PageTitle title={t("screens-user-list-title")}>
        <Can
          perform="Users:excel"
          yes={() => <ExcelButton onPressButton={handleExcel} />}
        />
      </PageTitle>

      <UserSearchForm />

      <ContentContainer>{renderTable()}</ContentContainer>

      <Pagination paging={paging} onPaginate={handlePaginate} />

      <Spacer height="5" />

      <Can
        perform="Users:add"
        yes={() => (
          <FloatingButtonAction
            icon="add"
            title={t("screens-list-acciones-add")}
            action={handleAdd}
          />
        )}
      />
    </PageContainer>
  );
};

const formSelector = formValueSelector("userSearchForm");

export default connect((state) => ({
  username: formSelector(state, "username"),
  active: formSelector(state, "active"),
  email: formSelector(state, "email"),
  role_id: formSelector(state, "role_id"),
}))(ScreensUserList);
