import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useHistory } from "react-router-dom";
import i18n from 'i18next';
import M from 'materialize-css';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import FormButton from '../../components/UI/FormButton';

import { 
    renderFieldOneColumn,
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns,
    renderCheckFieldTwoColumns
} from '../../components/UI/Fileds';

import { sendUser } from '../../actions/user';
import { startLoading, endLoading, fetchRoleList } from '../../actions/ui';

import { showError, showConfirmation } from '../../helpers/messages';
import { stringTrim } from '../../helpers/converter';

const ScreensUserForm = ({ submitting, handleSubmit, dirty }) => {
    
    const { t } = useTranslation();

    const { selected } = useSelector(state => state.user);

    const { roleList } = useSelector(state => state.ui);

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        dispatch(fetchRoleList()).then((res) => {
            if (res.ok) {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            }
        });

        if (selected) {
            const roles = selected.roles.map((role) => role.id);
            dispatch(initialize('userForm', { 
                id: selected.id,
                username: selected.username,
                active: selected.active,
                email: selected.email,
                role_id: roles
            }));
        } else {
            dispatch(initialize('userForm', { 
                username: '', 
                active: '', 
                email: '',
                role_id: [],
                password: '',
                confirm_password: ''
            }));
        }
    }, [dispatch, selected]);

    const handleSend = (values) => {
        dispatch(startLoading());

        dispatch(
            sendUser({Users: {
                ...values,
                username: stringTrim(values.username),
                email: stringTrim(values.email),
                password: stringTrim(values.password),
                active: (values.active) ? 1 : 0,
            }})
        ).then((res) => {
            dispatch(endLoading());

            if (res.ok) {
                history.goBack();
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                } 
            );
        } else {
            history.goBack();
        }        
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                {/* Campos del formulario */}
                <div className="row margin">
                    {
                        (selected)
                            ? (
                                <Field
                                    type="hidden" 
                                    name="id" 
                                    id="id"
                                    component={ renderFieldOneColumn }
                                />
                            )
                            : ''
                    }

                    <Field type="text" name="username" id="username" 
                        label={ t('screens-user-list-username') } 
                        component={ renderFieldTwoColumns } 
                        icon="person_outline" 
                        key="username"
                    />

                    <Field type="checkbox" name="active" id="active" 
                        className="filled-in"
                        label={ t('screens-user-list-active') } 
                        component={ renderCheckFieldTwoColumns } 
                        icon="check"
                        key="active"
                    />
                </div>
                <div className="row margin">
                    <Field type="text" name="email" id="email" 
                        label={ t('screens-user-list-email') } 
                        component={ renderFieldTwoColumns } 
                        icon="email" 
                        key="email" 
                    />

                    <Field name="role_id" id="role_id" 
                        type="select-multiple"
                        label={ t('screens-user-list-role') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="bookmark" 
                        key="role_id"
                        multiple={ true } 
                    >
                        <option value="" disabled>{ t('form-select-choose-one') }</option>
                        {
                            roleList.map(role => (
                                <option value={ role.id } key={ role.id }>{ role.description }</option>
                            ))
                        }
                    </Field>
                </div>

                <div className="row">
                    <Field type="password" name="password" id="password" 
                        label={ t('screens-profile-password') } 
                        placeholder=""
                        component={ renderFieldTwoColumns } 
                        icon="lock" 
                        key="password"
                    />

                    <Field type="password" name="confirm_password" id="confirm_password" 
                        label={ t('screens-profile-confirm-password') } 
                        placeholder=""
                        component={ renderFieldTwoColumns } 
                        icon="lock_outline" 
                        key="confirm_password"
                    />
                </div>

                {/* Botones del formulario */}
                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('form-button-cancel') }
                            disabled={ submitting }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </form>
        );
    };
    return (
        <PageContainer>    
            <PageTitle title={ t('screens-user-form-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.username) {
        errors.username = i18n.t('validator-error-username');
    }

    if (!values.email) {
        errors.email = i18n.t('validator-error-email');
    }

    if (!values.role_id) {
        errors.role_id = i18n.t('validator-error-role');
    }

    if (!values.id) {
        if (!values.password) {
            errors.password = i18n.t('validator-error-password');
        }
        
        if (!values.confirm_password) {
            errors.confirm_password = i18n.t('validator-error-confirm-password');
        }
    
        if (values.password !== values.confirm_password) {
            errors.confirm_password = i18n.t('validator-error-password-confirm-password');
        }
    } else {
        if (values.password !== values.confirm_password) {
            errors.confirm_password = i18n.t('validator-error-password-confirm-password');
        }
    }
    
    return errors;
};

export default reduxForm({
    form: 'userForm',
    validate: validate,
    destroyOnUnmount: false //true
})(ScreensUserForm);