import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import i18n from 'i18next';

import './Login.css';

import { forgetPassword } from '../../actions/auth';

import { showError, showSuccess } from '../../helpers/messages';
import { stringTrim } from '../../helpers/converter';

const renderField = ({
    input,
    label,
    type,
    meta: { touched, error },
    icon,
    id,
    key
}) => (

    <div className="row margin">
        <div className="input-field col s12">
            <i className="material-icons prefix pt-2">{icon}</i>
            <input {...input} key={key} id={id} type={type} />
            <label htmlFor={input.name} className="center-align">
                {label}
            </label>
            <small className="errorTxt6">
                <div id="crole-error" className="error">
                    {touched && error}
                </div>
            </small>
        </div>
    </div>

);

const validate = (values) => {
    const errors = {};

    if (!values.username) {
        errors.username = i18n.t('validator-error-username');
    }

    return errors;
}

const ScreensUserForgetPassword = ({ history, submitting, handleSubmit }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const handleForgetPassword = (values) => {
        dispatch(
            forgetPassword({
                ...values,
                username: stringTrim(values.username)
            })
        ).then((res) => {
            if (res.ok) {
                history.replace('/login');
                showSuccess(t('message-title-success'), t('message-review-email'));
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const renderForm = () => {

        return (
            <>
                <form className="login-form" id="forget-password-form" onSubmit={handleSubmit(handleForgetPassword)}>
                    <div className="row">
                        <div className="input-field col s12" style={{ 'text-align': 'center' }}>
                            <img src="/assets/images/logo.png" alt="ERP - Sistema de Gestión" className="responsive-img" style={{ maxWidth: "35%", "marginRight": "15px" }} />
                            <h5 style={{ 'font-size': '20px' }}>
                                {t('forget_password_title')}
                            </h5>
                        </div>
                    </div>
                    <Field type="text" name="username" id="username"
                        label={t('input_username')}
                        component={renderField}
                        icon="person_outline"
                        key="username"
                    />

                    <div className="row">
                        <div className="input-field col s12">
                            <button type="submit" className="btn waves-effect waves-light border-round gradient-45deg-purple-deep-orange col s12" disabled={submitting}>
                                {t('form-button-send')}
                            </button>
                        </div>
                    </div>
                </form>
            </>
        );

    };

    return (
        <div className="row">
            <div className="col s12">
                <div className="container">
                    <div id="login-page" className="row">
                        <div className="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">

                            {renderForm()}

                        </div>
                    </div>
                </div>
                <div className="content-overlay"></div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: 'forgetPasswordForm',
    validate: validate,
    destroyOnUnmount: true
})(ScreensUserForgetPassword);