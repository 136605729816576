import React from 'react';

const FloatingButtonAction = ({ action, icon, title = '' }) => {
    return (
        <div
            style={{bottom: "40px", right: "40px"}} 
            className="fixed-action-btn direction-top"
        >
            <button 
                onClick={ action } 
                title={ title } 
                className="btn-floating btn-large gradient-45deg-light-blue-cyan gradient-shadow"
            >
                <i className="material-icons">{ icon }</i>
            </button>
        </div>
    )
};

export default FloatingButtonAction;
