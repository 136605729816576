import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

import Can from '../../routes/Can';

import { showError, showConfirmation, showSuccess } from '../../helpers/messages';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import ActionButton from '../../components/UI/ActionButton';
import FloatingButtonAction from '../../components/UI/FloatingButtonAction';
import Pagination from '../../components/UI/Pagination';

import { 
    fetchAreas,  
    selectArea, 
    deleteArea } from '../../actions/area';
import { startLoading, endLoading } from '../../actions/ui';

const ScreensAreaList = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { list, paging } = useSelector(state => state.area);

    const history = useHistory();

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchAreas(options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    }, [dispatch, t]);

    useEffect(() => {
        fetchData({ ...paging });
    }, [dispatch, fetchData]);

    useEffect(() => {
        dispatch(selectArea(null));
    }, [dispatch]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, page: index });
    };

    const handleEdit = (item) => {
        dispatch(selectArea(item));
        history.push('/demarcaciones/formulario');
    };

    const handleDelete = (id, name) => {
        showConfirmation(
            t('message-title-confirmation'),
            t('message-delete-area-text', { name }),
            t('button-title-delete'),
            t('button-title-cancel'),
            () => {
                dispatch(startLoading());
                dispatch(
                    deleteArea(id)
                ).then((res) => {
                    dispatch(endLoading());
                    if (res.ok) {
                        showSuccess(t('message-title-success'), t(res.message));
                        fetchData({ ...paging, page: 1 });
                    } else {
                        showError(t('message-title-error'), t(res.message));
                    }
                });
            }
        );
    };

    const handleAdd = () => {
        dispatch(selectArea(null));
        history.push('/demarcaciones/formulario');
    };

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <th>{ t('screens-area-list-nombre') }</th>
                        <th>{ t('screens-list-acciones') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.id }>
                                <td>{ item.label }</td>
                                <td>
                                    <Can perform="Demarcaciones:edit" yes={() => (
                                        <ActionButton icon="edit" 
                                        tooltip={ t('screens-list-acciones-edit') }
                                        extraClass="btn-floating btn-small waves-effect waves-light green darken-3"                                        onClick={ () => handleEdit(item) } />
                                    )} />
                                    <Can perform="Demarcaciones:delete" yes={() => (
                                        <ActionButton icon="delete_forever"
                                        tooltip={ t('screens-list-acciones-delete') } 
                                        extraClass="btn-floating btn-small waves-effect waves-light red darken-3 ml-3"
                                        onClick={ () => handleDelete(item.id, item.nombre) } />
                                    )} />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>
            <PageTitle title={ t('screens-area-list-title') } />

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <Spacer height="5" />

            <Can perform="Demarcaciones:add" yes={() => (
                <FloatingButtonAction icon="add" 
                    title={ t('screens-list-acciones-add') } action={ handleAdd }
                />
            )} />
        </PageContainer>
    );

};

export default ScreensAreaList;