// Reglas para menús
const staticAdministracionMenu = ['Administracion:menu', 'Users:index', 'RolesPermissions:index', 'Demarcaciones:index'];

const staticAlumnosMenu = ['Alumnos:menu', 'Alumnos:index', 'Alumnos:load', 'AlumnosTitulaciones:index'];

const staticCursosMenu = ['Cursos:menu', 'Cursos:index', 'AlumnosCursos:index', 'Organizadores:index', 'FormadoresHomologados:index', 'Cursos:cargarCursosOnline', 'Clientes:index'];

const staticDiplomasMenu = ['Diplomas:menu', 'Diplomas:index', 'AlumnosDiplomas:index'];

const staticDocumentosMenu = ['Documentos:menu', 'Documentos:index', 'DocumentosCursos:index', 'DocumentosAlumnos:index'];

const staticTareasMenu = ['Tareas:menu', 'Tareas:index', 'TareasAlumnos:index'];

const staticBibliotecaMenu = ['Biblioteca:menu', 'BibliotecaLibros:index', 'BibliotecaSecciones:index', 'BibliotecaSocios:index'];

// Reglas para acciones.

// Demarcaciones
const staticDemarcacionesActions = [
    'Demarcaciones:add', 'Demarcaciones:edit', 'Demarcaciones:delete'
];

// Biblioteca
const staticLibrosActions = [
    'BibliotecaLibros:index', 'BibliotecaLibros:excel', 'BibliotecaLibros:add', 'BibliotecaLibros:edit', 'BibliotecaLibros:delete'
];
const staticLibrosSeccionesActions = [
    'BibliotecaSecciones:index', 'BibliotecaSecciones:excel', 'BibliotecaSecciones:add', 'BibliotecaSecciones:edit', 'BibliotecaSecciones:delete'
];
const staticLibrosSociosActions = [
    'BibliotecaSocios:index', 'BibliotecaSocios:excel', 'BibliotecaSocios:add', 'BibliotecaSocios:delete'
];

// Cursos
const staticCursosActions = [
    'Cursos:index', 'Cursos:excel', 'Cursos:add', 'Cursos:edit', 'Cursos:delete', 'Cursos:generarCertificados', 'Cursos:descargarCertificados', 'Cursos:cargarInscripcionesPresencial', 'Cursos:cargarInscripcionesVideo', 'Cursos:verInscripciones', 'Cursos:view'
];

// Titulaciones.
const staticAlumnosTitulacionesActions = [
    'AlumnosTitulaciones:index', 'AlumnosTitulaciones:edit', 'AlumnosTitulaciones:delete', 'AlumnosTitulaciones:excel'
];

// Diplomas
const staticDiplomasActions = [
    'Diplomas:index', 'Diplomas:add', 'Diplomas:descargar', 'Diplomas:delete', 'Diplomas:excel'
];
const staticAlumnosDiplomasActions = [
    'AlumnosDiplomas:index', 'AlumnosDiplomas:descargar', 'AlumnosDiplomas:excel'
];

// Documentos
const staticDocumentosActions = [
    'Documentos:index', 'Documentos:add', 'Documentos:excel', 'Documentos:descargar', 'Documentos:edit', 'Documentos:delete'
];
const staticDocumentosCursosActions = [
    'DocumentosCursos:index', 'DocumentosCursos:add', 'DocumentosCursos:excel','DocumentosCursos:delete'
];
const staticDocumentosAlumnosActions = [
    'DocumentosAlumnos:index', 'DocumentosAlumnos:add', 'DocumentosAlumnos:excel', 'DocumentosAlumnos:delete'
];

// Organizadores
const staticOrganizadoresActions = [
    'Organizadores:index', 'Organizadores:add', 'Organizadores:excel', 'Organizadores:edit', 'Organizadores:delete'
];

// Alumnos
const staticAlumnosActions = [
    'Alumnos:index', 'Alumnos:add', 'Alumnos:excel', 'Alumnos:edit', 'Alumnos:delete', 'Alumnos:titulaciones', 'Alumnos:downloadFoto', 'Alumnos:downloadAdjunto', 'Alumnos:titulaciones:enviar',
    'Alumnos:view'
];

// Inscripciones
const staticInscripcionesActions = [
    'AlumnosCursos:index', 'AlumnosCursos:add', 'AlumnosCursos:excel', 'AlumnosCursos:edit', 'AlumnosCursos:delete', 'AlumnosCursos:descargarCertificado',  'AlumnosCursos:cursosRealizados', 'AlumnosCursos:cursosRealizadosExcel'
];

// Tareas
const staticTareasActions = [
    'Tareas:index', 'Tareas:add', 'Tareas:excel', 'Tareas:edit', 'Tareas:delete'
];
const staticTareasAlumnosActions = [
    'TareasAlumnos:index', 'TareasAlumnos:add', 'TareasAlumnos:excel', 'TareasAlumnos:aprobar', 'TareasAlumnos:delete', 'TareasAlumnos:adjuntarArchivo', 'TareasAlumnosDocumentos:index', 'TareasAlumnosDocumentos:add', 'TareasAlumnosDocumentos:descargar', 'TareasAlumnosDocumentos:delete'
];

// Formadores
const staticFormadoresActions = [
    'FormadoresHomologados:index', 'FormadoresHomologados:add', 'FormadoresHomologados:excel', 'FormadoresHomologados:edit', 'FormadoresHomologados:delete'
];

// Usuarios
const staticUsuariosActions = [
    'Users:index', 'Users:add', 'Users:add', 'Users:excel', 'Users:edit', 'Users:delete'
];

// Clientes
const staticCustomersMenu = ['Customers:menu', 'Customers:index', 'Customers:add', 'Customers:edit', 'Customers:delete'];

const staticCustomersActions = [
    'Customers:index', 'Customers:add', 'Customers:edit', 'Customers:delete'
];

const rbac_rules = {
    role_administrador: {
        static: [
            'Home',
            'Server:deleteCache',
            'Users:profile',
            ...staticAdministracionMenu,
            // ...staticAlumnosMenu,
            // ...staticCursosMenu,
            // ...staticDiplomasMenu,
            // ...staticDocumentosMenu,
            // ...staticTareasMenu,
            // ...staticBibliotecaMenu,
            ...staticCustomersMenu,
            // ...staticDemarcacionesActions,
            // ...staticLibrosActions, ...staticLibrosSeccionesActions, ...staticLibrosSociosActions,
            // ...staticCursosActions,
            // ...staticAlumnosTitulacionesActions,
            // ...staticDiplomasActions, ...staticAlumnosDiplomasActions,
            // ...staticDocumentosActions, ...staticDocumentosCursosActions, ...staticDocumentosAlumnosActions,
            // ...staticOrganizadoresActions,
            // ...staticAlumnosActions,
            // ...staticInscripcionesActions,
            // ...staticTareasActions, ...staticTareasAlumnosActions,
            // ...staticFormadoresActions,
            ...staticUsuariosActions,
            ...staticCustomersActions
        ],
        dynamic: {}
    },
    role_gestor: {
        static: [
            'Users:profile',
            'Home',
            'Administracion:menu', 'Demarcaciones:index',
            ...staticAlumnosMenu,
            ...staticCursosMenu,
            ...staticDiplomasMenu,
            ...staticDocumentosMenu,
            ...staticTareasMenu,
            ...staticBibliotecaMenu,
            ...staticDemarcacionesActions,
            ...staticLibrosActions, ...staticLibrosSeccionesActions, ...staticLibrosSociosActions,
            ...staticCursosActions,
            ...staticAlumnosTitulacionesActions,
            ...staticDiplomasActions, ...staticAlumnosDiplomasActions,
            ...staticDocumentosActions, ...staticDocumentosCursosActions, ...staticDocumentosAlumnosActions,
            ...staticOrganizadoresActions,
            ...staticAlumnosActions,
            ...staticInscripcionesActions,
            ...staticTareasActions, ...staticTareasAlumnosActions,
            ...staticFormadoresActions
        ],
        dynamic: {}
    },
    role_organizador: {
        static: [
            'Users:profile',
            'Home',
            ...staticAlumnosMenu,
            'Alumnos:add', 'Alumnos:excel', 'Alumnos:titulaciones', 'Alumnos:view',
            'Alumnos:downloadFoto', 'Alumnos:downloadAdjunto',
            'AlumnosCursos:cursosRealizados', 'AlumnosCursos:cursosRealizadosExcel',
            'AlumnosCursos:index', 'AlumnosCursos:excel', 'AlumnosCursos:add', 'AlumnosCursos:descargarCertificado',
            'AlumnosTitulaciones:index',
            'Cursos:menu', 'Cursos:index', 'Cursos:add', 'Cursos:view', 'Cursos:verInscripciones'
        ],
        dynamic: {
            'Alumnos:edit': (user, alumno) => {
                return (user.organizadores[0].demarcacion_id === alumno.demarcacion_id);
            },
            'Alumnos:delete': (user, alumno) => {
                return (user.organizadores[0].demarcacion_id === alumno.demarcacion_id);
            },
            'Cursos:edit': (user, curso) => {
                return (user.organizadores[0].demarcacion_id === curso.demarcacion_id);
            },
            'Cursos:delete': (user, curso) => {
                return (user.organizadores[0].demarcacion_id === curso.demarcacion_id);
            },
            'AlumnosCursos:edit': (user, curso) => {
                return (user.organizadores[0].demarcacion_id === curso.demarcacion_id);
            },
            'AlumnosCursos:delete': (user, curso) => {
                return (user.organizadores[0].demarcacion_id === curso.demarcacion_id);
            }
        }
    },
    role_formador: {
        /*static: [
            'Users:profile',
            'Home',
            ...staticAlumnosMenu,
            'Alumnos:add', 'Alumnos:excel', 'Alumnos:titulaciones', 'Alumnos:view',
            'Alumnos:downloadFoto', 'Alumnos:downloadAdjunto',
            'AlumnosCursos:cursosRealizados', 'AlumnosCursos:cursosRealizadosExcel',
            'AlumnosCursos:index', 'AlumnosCursos:excel', 'AlumnosCursos:add', 'AlumnosCursos:descargarCertificado',
            'AlumnosTitulaciones:index',
            'Cursos:menu', 'Cursos:index', 'Cursos:add', 'Cursos:view', 'Cursos:verInscripciones'
        ],
        dynamic: {
            'Alumnos:edit': (user, alumno) => {
                return (user.alumnos[0].formadores_homologados[0].demarcacion_id === alumno.demarcacion_id);
            },
            'Alumnos:delete': (user, alumno) => {
                return (user.alumnos[0].formadores_homologados[0].demarcacion_id === alumno.demarcacion_id);
            },
            'Cursos:edit': (user, curso) => {
                return (user.alumnos[0].formadores_homologados[0].demarcacion_id === curso.demarcacion_id);
            },
            'Cursos:delete': (user, curso) => {
                return (user.alumnos[0].formadores_homologados[0].demarcacion_id === curso.demarcacion_id);
            },
            'AlumnosCursos:edit': (user, curso) => {
                return (user.alumnos[0].formadores_homologados[0].demarcacion_id === curso.demarcacion_id);
            },
            'AlumnosCursos:delete': (user, curso) => {
                return (user.alumnos[0].formadores_homologados[0].demarcacion_id === curso.demarcacion_id);
            }
        }*/
    },
    role_alumno: {
        static: [
            'Users:profile',
            'Home',
            'AreaAlumnos:menu',
            'AreaAlumno:AlumnosCursos:index',
            'AreaAlumno:AlumnosDiplomas:index',
            'AreaAlumno:TareasAlumnos:index', 'TareasAlumnosDocumentos:index', 'TareasAlumnosDocumentos:add',
            'AreaAlumno:Documentos:index',
            'BibliotecaLibros:index', 'BibliotecaLibros:excel'
        ],
        dynamic: {
            // 'AlumnosCursos:index': ({user, entity}) => {
            //     return true;
            // }
        }
    }
};

export default rbac_rules;
