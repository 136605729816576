import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import M from 'materialize-css';
import FormButton from '../../components/UI/FormButton';
import { renderFieldTwoColumns, renderSelectFieldTwoColumns } from '../../components/UI/Fileds';
import { setCustomerFilter } from '../../actions/customer';
import { startLoading, endLoading } from '../../actions/ui';
import { fetchCourseTypeList, fetchDemarcacionList } from '../../actions/ui';

const CustomerSearchForm = ({ submitting, handleSubmit }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { courseTypeList } = useSelector(state => state.ui);

    const { filter } = useSelector(state => state.customer);

    const { demarcacionList } = useSelector(state => state.ui);

    useEffect(() => {
        const collapsibles = document.querySelectorAll('.collapsible');
        M.Collapsible.init(collapsibles, {});

        dispatch(startLoading());
        Promise.all([
            dispatch(fetchCourseTypeList()),
            dispatch(fetchDemarcacionList())
        ]).then(() => {
            const selects = document.querySelectorAll('select');
            M.FormSelect.init(selects, {});
            dispatch(endLoading());
        });

        dispatch(initialize('customerSearchForm', filter));
    }, [dispatch, filter]);

    const handleReset = () => {
        dispatch(setCustomerFilter({}));
    };

    const handleSearch = (values) => {
        const collapsibles = document.getElementsByClassName('collapsible-search-form');
        const instance = M.Collapsible.getInstance(collapsibles[0]);
        instance.close();

        dispatch(setCustomerFilter(values));
    }


    const renderForm = () => {
        return (
            <form className="search-form" onSubmit={handleSubmit(handleSearch)}>
                <div className="row margin">

                    <Field type="text" name="nombre_comercial" id="nombre_comercial"
                        label={t('screens-customer-list-nombre_comercial')}
                        component={renderFieldTwoColumns}
                        icon="group"
                        key="nombre_comercial"
                    />
                    <Field type="text" name="razon_social" id="razon_social"
                        label={t('screens-customer-list-razon_social')}
                        component={renderFieldTwoColumns}
                        icon="business"
                        key="razon_social"
                    />
                </div>
                <div className="row margin">
                    <Field type="text" name="persona_contacto" id="persona_contacto"
                        label={t('screens-customer-list-persona_contacto')}
                        component={renderFieldTwoColumns}
                        icon="person"
                        key="persona_contacto"
                    />

                    <Field type="text" name="telefono" id="telefono"
                        label={t('screens-customer-list-telefono')}
                        component={renderFieldTwoColumns}
                        icon="phone"
                        key="telefono"
                    />
                </div>

                <div className="row margin">

                    <Field name="activo" id="activo"
                        type="select"
                        label={t('screens-customer-list-activo')}
                        component={renderSelectFieldTwoColumns}
                        icon="check_circle"
                        key="activo"
                    >
                        <option value="" disabled>{t('form-select-choose-one')}</option>
                        <option value="1">{t('form-select-yes')}</option>
                        <option value="0">{t('form-select-no')}</option>
                    </Field>

                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={t('search-form-button-title')}
                            disabled={submitting}
                        />

                        <FormButton type="reset"
                            title={t('reset-form-button-title')}
                            disabled={submitting}
                            onClick={handleReset}
                        />
                    </div>
                </div>

            </form>
        );
    };

    return (
        <ul className="collapsible popout collapsible-search-form">
            <li>
                <div className="collapsible-header">
                    <i className="material-icons">tune</i> {t('filter-title')}
                </div>
                <div className="collapsible-body">
                    {renderForm()}
                </div>
            </li>

        </ul>
    );
};

const validate = (values) => ({});

export default reduxForm({
    form: 'customerSearchForm',
    validate: validate,
    destroyOnUnmount: false
})(CustomerSearchForm);