import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field, change } from 'redux-form';
import { useHistory } from "react-router-dom";
import i18n from 'i18next';
import M from 'materialize-css';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import FormButton from '../../components/UI/FormButton';
import DatePicker from '../../components/UI/DatePicker';

import { 
    renderFieldOneColumn,
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns
} from '../../components/UI/Fileds';

import { sendCourse } from '../../actions/course';
import { 
    startLoading, 
    endLoading, 
    fetchCourseTypeList, 
    fetchDemarcacionList,
    fetchOrganizadorList,
    fetchFormadorList
} from '../../actions/ui';

import { showError, showConfirmation } from '../../helpers/messages';
import { dateToDatepicker, stringTrim } from '../../helpers/converter';

const ScreensCourseForm = ({ submitting, handleSubmit, dirty }) => {

    const { t } = useTranslation();

    const { user: userLogged} = useSelector(state => state.auth);

    const { selected } = useSelector(state => state.course);

    const { 
        courseTypeList, demarcacionList, organizadorList, formadorList 
    } = useSelector(state => state.ui);

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        dispatch(startLoading());

        let demarcacion_id = '-1';
        if (selected) {
            demarcacion_id = selected.demarcacion_id;
        }

        Promise.all([
            dispatch(fetchCourseTypeList()),
            dispatch(fetchDemarcacionList()),
            dispatch(fetchOrganizadorList({demarcacion_id})),
            dispatch(fetchFormadorList()),
        ]).then(() => {
            const selects = document.querySelectorAll('select');
            M.FormSelect.init(selects, {});
            dispatch(endLoading());
        });

        if (selected) {        
            dispatch(initialize('courseForm', {
                id: selected.id,
                titulo: selected.titulo, 
                horas: selected.horas,
                cursos_tipo_id: selected.cursos_tipo_id,
                demarcacion_id: selected.demarcacion_id,
                organizador_id: selected.organizador_id,
                formadores_homologado_id: selected.formadores_homologado_id,
                horas_asistencia: selected.horas_asistencia,
                fecha_inicio: selected.fecha_inicio,
                fecha_fin: selected.fecha_fin,
                lugar: selected.lugar,
                ciudad: selected.ciudad,
                pais: selected.pais,
                precio: selected.precio,
                plazas: selected.plazas,
                ocupadas: selected.ocupadas,
                num_unidad: selected.num_unidad,
                ciclo_formativo: selected.ciclo_formativo,
                datepicker_fecha_inicio: dateToDatepicker(selected.fecha_inicio),
                datepicker_fecha_fin: dateToDatepicker(selected.fecha_fin)
            }));
        } else {
            dispatch(initialize('courseForm', { 
                titulo: '', 
                horas: '1',
                cursos_tipo_id: '',
                demarcacion_id: '',
                organizador_id: '',
                formadores_homologado_id: '',
                horas_asistencia: '',
                fecha_inicio: '',
                fecha_fin: '',
                datepicker_fecha_inicio: '',
                datepicker_fecha_fin: '',
                lugar: '',
                ciudad: '',
                pais: '',
                precio: '',
                plazas: '',
                ocupadas: '0',
                num_unidad: '',
                ciclo_formativo: '' 
            }));
        }
    }, [dispatch, selected]);

    const handleSend = (values) => {
        // Validaciones.
        const opcionales = ['ocupadas', 'organizador_id', 'formadores_homologado_id', 'plazas', 'precio', 'ciudad', 'lugar'];
        const valores = [];
        let errors = false;
        const tipo = courseTypeList.find((type, index) => {
            return values.cursos_tipo_id === type.id;
        });
        tipo.cursos_tipos_settings.forEach(setting => {
            const { atributo, aplica } = setting;
            if (aplica) {
                let valor = values[atributo];
                if (!valor && !opcionales.includes(atributo)) {
                    //document.getElementById(atributo).focus();
                    showError(t('message-title-error'), t(`validator-error-${atributo}`));
                    errors = true;
                    //return;
                } else {
                    valores[atributo] = stringTrim(valor);
                }
            }
        });

        if (values.fecha_inicio && values.fecha_fin) {
            if (values.fecha_inicio > values.fecha_fin) {
                showError(t('message-title-error'), t(`validator-error-rango_fechas`));
                errors = true;
            }
        }

        if (errors) {
            return;
        }

        if (selected) {
            valores['id'] = values.id;
        }
        valores['titulo'] = values.titulo;
        valores['cursos_tipo_id'] = values.cursos_tipo_id;

        dispatch(startLoading());

        dispatch(
            sendCourse({Cursos: {
                ...valores
                // ...values,
                // titulo: stringTrim(values.titulo),
                // lugar: stringTrim(values.lugar),
                // ciudad: stringTrim(values.ciudad),
                // num_unidad: stringTrim(values.num_unidad),
                // ciclo_formativo: stringTrim(values.ciclo_formativo)
            }})
        ).then((res) => {
            dispatch(endLoading());

            if (res.ok) {
                history.goBack();
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                } 
            );
        } else {
            history.goBack();
        }        
    };

    const handleTipoChange = (event) => {
        if (event.target.value) {
            const tipo = courseTypeList.find((type, index) => {
                return event.target.value === type.id;
            });

            tipo.cursos_tipos_settings.forEach(setting => {
                const { atributo, aplica, valor_por_defecto, valor_fijo } = setting;
                const inputBlock = document.getElementById(`input-block-${ atributo }`);

                if (aplica) {
                    if (atributo === 'fecha_inicio' || atributo === 'fecha_fin') {
                        document.getElementById(`input-block-datepicker_${atributo}`).classList.remove('hide');
                    } else {
                        inputBlock.classList.remove('hide');
                    }
                    if (valor_fijo) {
                        document.getElementById(atributo).setAttribute('readonly', 'readonly');
                        dispatch(change('courseForm', atributo, valor_por_defecto));
                    } else if (!event.target.value && valor_por_defecto) {
                        dispatch(change('courseForm', atributo, valor_por_defecto));
                    } else {
                        document.getElementById(atributo).removeAttribute('readonly');
                    }
                } else {
                    if (atributo === 'fecha_inicio' || atributo === 'fecha_fin') {
                        document.getElementById(`input-block-datepicker_${atributo}`).classList.add('hide');
                    } else {
                        inputBlock.classList.add('hide');
                    }
                }
            });

        } else {
            courseTypeList[0].cursos_tipos_settings.forEach(setting => {
                const { atributo } = setting;
                const inputBlock = document.getElementById(`input-block-${ atributo }`);
                inputBlock.classList.add('hide');
            });
            document.getElementById('input-block-datepicker_fecha_inicio').classList.add('hide');
            document.getElementById('input-block-datepicker_fecha_fin').classList.add('hide');
        }        
    };

    const handleDemarcacionChange = (event) => {
        let demarcacion_id = '-1';
        if (event.target.value) {
            demarcacion_id = event.target.value;
        }
        dispatch(fetchOrganizadorList({demarcacion_id}))
            .then(() => {
                const select = document.getElementById('organizador_id');
                M.FormSelect.init(select, {});
                dispatch(change('courseForm', 'organizador_id', ''));
            });
        dispatch(fetchFormadorList({demarcacion_id}))
            .then(() => {
                const select = document.getElementById('formadores_homologado_id');
                M.FormSelect.init(select, {});
                dispatch(change('courseForm', 'formadores_homolodago_id', ''));
            });
    };

    const campoAplica = (cursos_tipo_id, campo) => {
        const tipo = courseTypeList.find((type, index) => {
            return type.id === cursos_tipo_id;
        });
        for (let setting of tipo.cursos_tipos_settings) {
            const { atributo, aplica } = setting;
            if (campo === atributo && aplica) {
                return true;
            }  
        }
        return false; 
    };

    const esReadOnly = (cursos_tipo_id, campo) => {
        const tipo = courseTypeList.find((type, index) => {
            return type.id === cursos_tipo_id;
        });
        for (let setting of tipo.cursos_tipos_settings) {
            const { atributo, valor_fijo } = setting;
            if (campo === atributo && valor_fijo) {
                return true;
            }  
        }
        return false;
    };

    const isValidArea = (demarcacion_id) => {
        if (userLogged.organizadores.length > 0) {
            const demarcacionUser = userLogged.organizadores[0].demarcacion.id;
            const demarcacionParentUser = userLogged.organizadores[0].demarcacion.parent_id;
            return (demarcacionUser === demarcacion_id || demarcacionParentUser === demarcacion_id);
            //return userLogged.organizadores[0].demarcacion_id === demarcacion_id;
        } else if (userLogged.alumnos.length > 0 && userLogged.alumnos[0].formadores_homologados.length > 0) {
            const demarcacionUser = userLogged.alumnos[0].formadores_homologados.demarcacion.id;
            const demarcacionParentUser = userLogged.alumnos[0].formadores_homologados.demarcacion.parent_id;
            return (demarcacionUser === demarcacion_id || demarcacionParentUser === demarcacion_id);
            //return userLogged.alumnos[0].formadores_homologados.demarcacion_id === demarcacion_id;
        } else {
            return true;
        }
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>

                <div className="row margin">
                    {
                        (selected)
                            ? (
                                <Field type="hidden" name="id" id="id"
                                    component={ renderFieldOneColumn }
                                />
                            )
                            : ''
                    }

                    {
                        (selected)
                        ? <div className={`col s12 m6`}>
                            <p>
                                <i className="material-icons prefix">bookmark</i>
                                {`${selected.cursos_tipo.nombre}`}
                            </p>
                        </div>
                        : <Field name="cursos_tipo_id" id="cursos_tipo_id" 
                            label={ t('screens-course-list-tipo') } 
                            component={ renderSelectFieldTwoColumns } 
                            icon="bookmark" 
                            key="cursos_tipo_id"
                            onChange={ handleTipoChange } 
                        >
                            <option value="">{ t('form-select-choose-one') }</option>
                            {
                                courseTypeList.map(courseType => (
                                    <option value={ courseType.id } key={ courseType.id }>{ courseType.nombre }</option>
                                ))
                            }
                        </Field>
                    }
                    
                    <Field type="text" name="titulo" id="titulo" 
                        label={ t('screens-course-list-titulo') } 
                        component={ renderFieldTwoColumns } 
                        icon="text_fields" 
                        key="titulo" 
                        maxLength="256"
                    />
                </div>

                <div className="row margin">
                        <Field name="demarcacion_id" id="demarcacion_id" 
                            label={ t('screens-course-list-demarcacion') } 
                            component={ renderSelectFieldTwoColumns } 
                            icon="location_on" 
                            key="demarcacion_id"
                            visible={ (selected) ? campoAplica(selected.cursos_tipo_id, 'demarcacion_id') : false } 
                            onChange={ handleDemarcacionChange } 
                        >
                            <option value="">{ t('form-select-choose-one') }</option>
                            {
                                demarcacionList.map(demarcacion => (
                                    (isValidArea(demarcacion.id))
                                    ? <option value={ demarcacion.id } key={ demarcacion.id }>{ demarcacion.nombre }</option>
                                    : <></>
                                ))
                            }
                        </Field>

                        <Field name="organizador_id" id="organizador_id" 
                            label={ t('screens-course-list-organizador') } 
                            component={ renderSelectFieldTwoColumns } 
                            icon="person" 
                            key="organizador_id"
                            visible={ (selected) ? campoAplica(selected.cursos_tipo_id, 'organizador_id') : false } 
                        >
                            <option value="">{ t('form-select-choose-one') }</option>
                            {
                                organizadorList.map(organizador => (
                                    <option value={ organizador.id } key={ organizador.id }>{ `${organizador.apellidos}, ${organizador.nombre}` }</option>
                                ))
                            }
                        </Field>
                        <Field name="formadores_homologado_id" id="formadores_homologado_id" 
                            label={ t('screens-course-list-formador') } 
                            component={ renderSelectFieldTwoColumns } 
                            icon="person_pin_circle" 
                            key="formadores_homologado_id"
                            visible={ (selected) ? campoAplica(selected.cursos_tipo_id, 'formadores_homologado_id') : false } 
                        >
                            <option value="">{ t('form-select-choose-one') }</option>
                            {
                                formadorList.map(formador => (
                                    <option value={ formador.id } key={ formador.id }>{ `${formador.alumno.apellidos}, ${formador.alumno.nombre}` }</option>
                                ))
                            }
                        </Field>
                        <Field type="number" name="horas" id="horas" 
                            label={ t('screens-course-list-horas') } 
                            placeholder=""
                            component={ renderFieldTwoColumns } 
                            icon="watch_later" 
                            key="horas"
                            step=".01"
                            min="0"
                            visible={ (selected) ? campoAplica(selected.cursos_tipo_id, 'horas') : false }
                            editable={ !selected || (selected && !esReadOnly(selected.cursos_tipo_id, 'horas')) }
                        />
                        <Field type="number" name="horas_asistencia" id="horas_asistencia" 
                            label={ t('screens-course-list-horas_asistencia') } 
                            placeholder=""
                            component={ renderFieldTwoColumns } 
                            icon="watch" 
                            key="horas_asistencia"
                            step=".01"
                            min="0"
                            visible={ (selected) ? campoAplica(selected.cursos_tipo_id, 'horas_asistencia') : false }
                        />
                        <DatePicker form="courseForm" name="fecha_inicio" 
                            value={(selected) ? selected.fecha_inicio : ''}
                            label={ t('screens-course-list-fecha_inicio') }
                            render={ renderFieldTwoColumns }
                            visible={ (selected) ? campoAplica(selected.cursos_tipo_id, 'fecha_inicio') : false }
                        />                        
                        <DatePicker form="courseForm" name="fecha_fin" 
                            value={(selected) ? selected.fecha_fin : ''}
                            label={ t('screens-course-list-fecha_fin') }
                            render={ renderFieldTwoColumns }
                            visible={ (selected) ? campoAplica(selected.cursos_tipo_id, 'fecha_fin') : false }
                        />                        
                        <Field type="text" name="lugar" id="lugar" 
                            label={ t('screens-course-list-lugar') } 
                            component={ renderFieldTwoColumns } 
                            icon="business" 
                            key="lugar" 
                            maxLength="128"
                            visible={ (selected) ? campoAplica(selected.cursos_tipo_id, 'lugar') : false }
                        />
                        <Field type="text" name="ciudad" id="ciudad" 
                            label={ t('screens-course-list-ciudad') } 
                            component={ renderFieldTwoColumns } 
                            icon="location_city" 
                            key="ciudad" 
                            maxLength="128"
                            visible={ (selected) ? campoAplica(selected.cursos_tipo_id, 'ciudad') : false }
                        />
                        <Field type="text" name="pais" id="pais" 
                            label={ t('screens-course-list-pais') } 
                            component={ renderFieldTwoColumns } 
                            icon="map" 
                            key="pais" 
                            maxLength="128"
                            visible={ (selected) ? campoAplica(selected.cursos_tipo_id, 'pais') : false }
                        />
                        <Field type="number" name="precio" id="precio" 
                            label={ t('screens-course-list-precio') } 
                            placeholder=""
                            component={ renderFieldTwoColumns } 
                            icon="monetization_on" 
                            key="precio"
                            step=".01"
                            visible={ (selected) ? campoAplica(selected.cursos_tipo_id, 'precio') : false }
                        />
                        <Field type="number" name="plazas" id="plazas" 
                            label={ t('screens-course-list-plazas') } 
                            placeholder=""
                            component={ renderFieldTwoColumns } 
                            icon="event_seat" 
                            key="plazas"
                            step="1"
                            visible={ (selected) ? campoAplica(selected.cursos_tipo_id, 'plazas') : false }
                        />
                        <Field type="text" name="num_unidad" id="num_unidad" 
                            label={ t('screens-course-list-num_unidad') } 
                            component={ renderFieldTwoColumns } 
                            icon="book" 
                            key="num_unidad" 
                            maxLength="128"
                            visible={ (selected) ? campoAplica(selected.cursos_tipo_id, 'num_unidad') : false }
                        />
                        <Field type="text" name="ciclo_formativo" id="ciclo_formativo" 
                            label={ t('screens-course-list-ciclo_formativo') } 
                            component={ renderFieldTwoColumns } 
                            icon="school" 
                            key="ciclo_formativo" 
                            maxLength="128"
                            visible={ (selected) ? campoAplica(selected.cursos_tipo_id, 'ciclo_formativo') : false }
                        />
                    <Field type="hidden" name="ocupadas" id="ocupadas" 
                        component={ renderFieldTwoColumns } 
                        key="ocupadas"
                        visible={ false }
                    />
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('form-button-cancel') }
                            disabled={ submitting }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </form>
        );
    };

    return (
        <PageContainer>    
            <PageTitle title={ t('screens-course-form-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.titulo) {
        errors.titulo = i18n.t('validator-error-titulo');
    }

    if (!values.cursos_tipo_id) {
        errors.cursos_tipo_id = i18n.t('validator-error-cursos-tipo');
    }

    return errors;
};

export default reduxForm({
    form: 'courseForm',
    validate: validate,
    destroyOnUnmount: false //true
})(ScreensCourseForm);