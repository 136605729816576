import TYPES from './types';
import { apiClientWithToken, downloadExcelFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchTrainers = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/formadores-homologados', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setTrainers(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setTrainers = (data) => ({
   type: TYPES.SET_TRAINERS, payload: data
});

export const selectTrainer = (data) => ({
    type: TYPES.SELECT_TRAINER, payload: data
});

export const downloadExcel = (filter) => {
    return async () => {
        try {

            const res = await downloadExcelFile(
                '/formadores-homologados/excel', 
                'formadores-homologados.xlsx',
                filter,{
                    nombre: 'Alumnos',
                    apellidos: 'Alumnos',
                    email: 'Alumnos',
                    demarcacion_id: 'FormadoresHomologados'
                }
            );
            const blob = await res.blob();
            fileDownload(blob, 'formadores-homologados.xlsx');

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const setTrainerFilter = (filter) => ({
    type: TYPES.SET_TRAINER_FILTER, payload: filter
});

export const deleteTrainer = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/formadores-homologados/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendTrainer = (trainer) => {
    return async () => {
        try {
            let res;

            if (trainer.FormadoresHomologados.id) {
                res = await apiClientWithToken().put(
                    `/formadores-homologados/${trainer.FormadoresHomologados.id}`,
                    trainer
                );
            } else {
                res = await apiClientWithToken().post(
                    '/formadores-homologados', trainer
                );
            }
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};