import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import M from 'materialize-css';

import { showError } from '../../helpers/messages';

import { dateToDatepicker } from '../../helpers/converter';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Pagination from '../../components/UI/Pagination';
import ExcelButton from '../../components/UI/ExcelButton';
import Spacer from '../../components/UI/Spacer';
import SortingHeader from '../../components/UI/SortingHeader';
import PdfButton from '../../components/UI/PdfButton';
import ActionButton from '../../components/UI/ActionButton';

import { 
    fetchStudentCourseDone, 
    downloadStudentCourseDoneExcel,
    downloadCertificate,
    downloadCertificateAttendance
} from '../../actions/studentArea';
import { startLoading, endLoading } from '../../actions/ui';

const ScreensStudentAreaCourses = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { list, paging, horas } = useSelector(state => state.studentCourseDone);
    const { sort, direction } = paging;

    const { user } = useSelector(state => state.auth);
    
    const selected = user.alumnos[0] ? user.alumnos[0] : user.formadores_homologados[0].alumno
    console.log(user.alumnos[0]);
    const [inscripcion, setInscripcion] = useState({});

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchStudentCourseDone(options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });

        const elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {});
    }, [dispatch, t]);

    useEffect(() => {
        fetchData({ ...paging });
    }, [dispatch, fetchData]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, page: index });
    };

    const handleSort = (name) => {
        let newDirection = 'asc';
        if (sort === name) {
            newDirection = (direction === 'asc') ? 'desc' : 'asc';
        }
        fetchData({ ...paging, sort: name, direction: newDirection });
    };

    const handleExcel = () => {
        dispatch(startLoading());
        dispatch(
            downloadStudentCourseDoneExcel({})
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleDownloadCertificate = (item) => {
        dispatch(downloadCertificate(item.id));
    };
    
    const handleDownloadCertificateAttendance  = (item) => {
        dispatch(downloadCertificateAttendance(item.id));
    };

    const handleView = (item) => {
        setInscripcion(item);

        const elem = document.getElementById('modal-inscripcion-info');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };
    
    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <th>{ t('screens-student-course-done-tipo') }</th>
                        <SortingHeader title={ t('screens-student-course-done-titulo') }
                            direction={ (sort === 'titulo') ? direction : '' }
                            onClick={ () => handleSort('titulo') }
                        />
                        <th>{ t('screens-course-list-formador') }</th>
                        <SortingHeader title={ t('screens-student-course-done-fecha_inicio') }
                            direction={ (sort === 'fecha_inicio') ? direction : '' }
                            onClick={ () => handleSort('fecha_inicio') }
                        />
                        <SortingHeader title={ t('screens-student-course-done-fecha_fin') }
                            direction={ (sort === 'fecha_fin') ? direction : '' }
                            onClick={ () => handleSort('fecha_fin') }
                        />
                        <th>{ t('screens-student-course-done-horas') }</th>
                        <th>{ t('screens-student-course-done-evaluacion') }</th>
                        <th>{ t('screens-student-course-done-aprobado') }</th>
                        <th>{ t('screens-list-acciones') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.curso.id }>
                                <td>{ item.curso.cursos_tipo.nombre }</td>
                                <td>{ item.curso.titulo }</td>
                                { (item.curso.formadores_homologado !=null ) ?
                                   <td>{ item.curso.formadores_homologado.alumno.label }</td>  : <td>{ 'Sin definir' }</td>}
                                <td>{ dateToDatepicker(item.curso.fecha_inicio) }</td>
                                <td>{ dateToDatepicker(item.curso.fecha_fin) }</td>
                                <td>{ item.curso.horas }</td>
                                <td>{ item.evaluacion }</td>
                                <td>{ 
                                    ((item.curso.cursos_tipo.nombre === 'Online' && item.aprobado))
                                    ? t('si') 
                                    : ((item.curso.cursos_tipo.nombre === 'Online') ? t('no') : '') }
                                </td>
                                <td>
                                    <ActionButton icon="search" 
                                        tooltip={ t('screens-list-acciones-view') }
                                        extraClass="btn-floating btn-small waves-effect waves-light cyan darken-2 ml-3"
                                        onClick={ () => handleView(item) } />
                                    <ActionButton icon="file_download" 
                                        tooltip={t('screens-list-acciones-certificado-asistencia')}
                                        extraClass="btn-floating btn-small waves-effect waves-light cyan darken-2" 
                                        onClick={ () => handleDownloadCertificateAttendance(item) } />
                                    {
                                        (item.num_certificado || (item.curso.cursos_tipo.nombre === 'Online' && item.aprobado) || (item.curso.cursos_tipo.nombre === 'Videoconferencia' && item.aprobado))
                                        ? <PdfButton 
                                            onPressButton={ () => handleDownloadCertificate(item) } />
                                        : ''
                                    }                                    
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    const renderInscripcion = (inscripcion) => {
        return (
            <blockquote className="red-text">
                {
                    (inscripcion.curso)
                    ? <>
                        <label>{inscripcion.curso.titulo}</label><br />
                        <label>{`${inscripcion.curso.cursos_tipo.nombre} (${inscripcion.curso.horas} hr.)`}</label><br />
                        {
                            (inscripcion.curso.fecha_inicio)
                            ? <><label>{`${dateToDatepicker(inscripcion.curso.fecha_inicio)}${(inscripcion.curso.fecha_fin) ? ' - ' + dateToDatepicker(inscripcion.curso.fecha_fin): ''}`}</label><br /></>
                            : <></>
                        }
                        {
                            (inscripcion.evaluacion)
                            ? <><label>inscripcion.evaluacion</label><br/></>
                            : <></>
                        }
                        {
                            (inscripcion.aprobado)
                            ? <><label>{t('Aprobado')}</label><br/></>
                            : <></>
                        }
                        {
                            (inscripcion.curso.ciudad)
                            ? <><label>{inscripcion.curso.ciudad}</label><br/></>
                            : <></>
                        }
                        {
                            (inscripcion.curso.demarcacion)
                            ? <><label>{inscripcion.curso.demarcacion.nombre}</label><br/></>
                            : <></>
                        }
                        {
                            (inscripcion.alumno_grupo)
                            ? <><label>{`${t('Compañero/a')}: ${inscripcion.alumno_grupo.nombre} ${inscripcion.alumno_grupo.apellidos}`}</label><br/></>
                            : <></>
                        }
                    </>
                    : <></>
                }
            </blockquote>
        );
    };

    return (
        <PageContainer>
            <PageTitle title={ t('screens-student-course-done-title') }>
                <ExcelButton onPressButton={ handleExcel } />
            </PageTitle>

            <blockquote className="small red-text">
                <label>{ selected ? `${selected.nombre} ${selected.apellidos}` : '' }</label><br />
                {
                    (horas.AlumnosHorasPresenciales)
                    ? <>
                        <label>{ selected ? `${t('screens-course-students-list-horas_p')}: ${horas.AlumnosHorasPresenciales.horas_presenciales}` : ''}</label><br />
                        <label>{ selected ? `${t('screens-course-students-list-horas_t')}: ${horas.AlumnosHorasTalleres.horas_talleres}`: ''}</label><br />
                        <label>{ selected ? `${t('screens-course-students-list-horas_o')}: ${horas.AlumnosHorasOnline.horas_online}`: ''}</label><br />
                        <label>{ selected ? `${t('screens-course-students-list-horas_v')}: ${horas.AlumnosHorasVideoconferencia.horas_videoconferencia}`: ''}</label><br />
                    {/**<label>{ selected ? `${t('screens-course-students-list-horas_s')}: ${horas.horas_totales}`: ''}</label><br />*/}
                    </>
                    : ''
                }
            </blockquote>

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <div id="modal-inscripcion-info" className="modal">
                <div className="modal-content">
                    {
                        (inscripcion)
                        ? renderInscripcion(inscripcion)
                        : <p>No ha seleccionado ninguna inscripción.</p>
                    }
                </div>
            </div>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <Spacer height="5" />
        </PageContainer>
    );
};

export default ScreensStudentAreaCourses;