import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import M from 'materialize-css';

import FormButton from '../../components/UI/FormButton';
import { 
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns,
    renderCheckFieldTwoColumns
} from '../../components/UI/Fileds';

import { 
    fetchCourseTypeList,
    fetchDemarcacionList,
    fetchFormadorList
} from '../../actions/ui';

import { setCourseFilter } from '../../actions/course';

const CourseSearchForm = ({ submitting, handleSubmit }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { filter } = useSelector(state => state.course);

    const { courseTypeList, demarcacionList, formadorList } = useSelector(state => state.ui);
    

    useEffect(() => {
        const collapsibles = document.querySelectorAll('.collapsible');
        M.Collapsible.init(collapsibles, {});

        dispatch(fetchCourseTypeList()).then((res) => {
            if (res.ok) {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            }
        });
        dispatch(fetchFormadorList()).then((res) => {
            if (res.ok) {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            }
        });

        dispatch(fetchDemarcacionList()).then((res) => {
            if (res.ok) {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            }
        });
        
        dispatch(initialize('courseSearchForm', filter));
    }, [dispatch, filter]);
    
    const handleReset = () => {
        dispatch(setCourseFilter({}));
    };

    const handleSearch = (values) => {
        const collapsibles = document.getElementsByClassName('collapsible-search-form');
        const instance = M.Collapsible.getInstance(collapsibles[0]);
        instance.close();

        dispatch(setCourseFilter(values));
    }

    const renderForm = () => {
        return (
            <form className="search-form" onSubmit={ handleSubmit(handleSearch) }>

                <div className="row margin">
                    <Field type="text" name="titulo" id="titulo" 
                        label={ t('screens-course-list-titulo') } 
                        component={ renderFieldTwoColumns } 
                        icon="text_fields" 
                        key="titulo" 
                    />

                    <Field name="cursos_tipo_id" id="cursos_tipo_id" 
                        type="select-multiple"
                        label={ t('screens-course-list-tipo') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="bookmark" 
                        key="cursos_tipo_id" 
                        multiple={ true }
                    >
                        <option value="" disabled>{ t('form-select-choose-one') }</option>
                        {
                            courseTypeList.map(courseType => (
                                <option value={ courseType.id } key={ courseType.id }>{ courseType.nombre }</option>
                            ))
                        }
                    </Field>
                </div>

                <div className="row margin">
                    <Field type="text" name="poblacion" id="poblacion" 
                        label={ t('screens-student-degree-list-poblacion') } 
                        component={ renderFieldTwoColumns } 
                        icon="location_city" 
                        key="poblacion" 
                    />
                    <Field name="demarcacion_id" id="demarcacion_id" 
                        label={ t('screens-course-list-demarcacion') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="location_on" 
                        key="demarcacion_id" 
                    >
                        <option value="">{ t('form-select-choose-one') }</option>
                        {
                            demarcacionList.map(demarcacion => (
                                <option value={ demarcacion.id } key={ demarcacion.id }>{ demarcacion.nombre }</option>
                            ))
                        }
                    </Field>
                </div>
                <div className="row margin">
                    <Field name="formadores_homologado_id" id="formadores_homologado_id" 
                            label={ t('screens-course-list-formador') } 
                            component={ renderSelectFieldTwoColumns } 
                            icon="person_pin_circle" 
                            key="formadores_homologado_id"
                            /*visible={ (selected) ? campoAplica(selected.cursos_tipo_id, 'formadores_homologado_id') : false } */
                        >
                            <option value="">{ t('form-select-choose-one') }</option>
                            {
                                formadorList.map(formador => (
                                    <option value={ formador.id } key={ formador.id }>{ `${formador.alumno.apellidos}, ${formador.alumno.nombre}` }</option>
                                ))
                            }
                        </Field>
                </div>
                <div className="row margin">
                    <Field type="checkbox" name="programados" id="programados" 
                        className="filled-in"
                        label={ t('screens-course-list-programados') } 
                        component={ renderCheckFieldTwoColumns } 
                        icon="check"
                        key="programados"
                    />
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('search-form-button-title') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('reset-form-button-title') }
                            disabled={ submitting }
                            onClick={ handleReset }
                        />
                    </div>
                </div>

            </form>
        );
    };

    return (
        <ul className="collapsible popout collapsible-search-form">
            <li>
                <div className="collapsible-header">
                    <i className="material-icons">tune</i> { t('filter-title') }
                </div>
                <div className="collapsible-body">
                    { renderForm() }
                </div>
            </li>
            
        </ul>
    );
};

const validate = (values) => ({});

export default reduxForm({
    form: 'courseSearchForm',
    validate: validate,
    destroyOnUnmount: false
})(CourseSearchForm);