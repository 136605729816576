import React, { useState, useEffect } from 'react';
import { reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import M from 'materialize-css';

import PageContainer from '../../components/UI/PageContainer';
import PageTitle from '../../components/UI/PageTitle';
import ContentContainer from '../../components/UI/ContentContainer';
import Pagination from '../../components/UI/Pagination';
import Spacer from '../../components/UI/Spacer';
import ActionButton from '../../components/UI/ActionButton';
import FormButton from '../../components/UI/FormButton';

import { dateToDatepicker } from '../../helpers/converter';

import { showError } from '../../helpers/messages';

import { 
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns
} from '../../components/UI/Fileds';

import { 
    startLoading, 
    endLoading, 
    fetchCourseTypeList,
    fetchFormadorList
} from '../../actions/ui';

const ScreensCourseSelector = ({ onSelect, submitting, handleSubmit, reset, fetchCourseList }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { selected } = useSelector(state => state.course);

    const [{ paging, list }, setSearch] = useState({ paging: {}, list: [] });

    const { courseTypeList, formadorList } = useSelector(state => state.ui);

    useEffect(() => {

        // dispatch(fetchCourseTypeList()).then((res) => {
        //     if (res.ok) {
        //         const selects = document.querySelectorAll('select');
        //         M.FormSelect.init(selects, {});
        //     }
        // });
        Promise.all([
            dispatch(fetchCourseTypeList()),
            dispatch(fetchFormadorList())
        ]).then(() => {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            dispatch(endLoading());
        });

    }, []);

    const handleFetch = (resp) =>  {
        if (resp.ok) {
            const {count, current, page, pageCount } = resp.data.paging;
            setSearch({
                paging: { count, current, page, pageCount },
                list: resp.data.list
            });
        } else {
            showError(t('message-title-error'), t(resp.message));
        }
    };

    const handlePaginate = (index) => {
        const values = {
            titulo: document.getElementById('titulo').value,
            cursos_tipo_id: document.getElementById('cursos_tipo_id').value
        };
        dispatch(
            fetchCourseList({ ...paging, ...values, page: index })
        ).then(handleFetch);
    };

    const handleSearch = (values) => {
        dispatch(
            fetchCourseList({ ...paging, ...values, page: 1 })
        ).then(handleFetch);
    }

    const handleReset = () => {
        dispatch(reset('courseSelectorForm'));
    };

    const renderForm = () => {
        return (
            <form className="search-form" onSubmit={ handleSubmit(handleSearch) }>
                <div className="row margin">
                    <Field type="text" name="titulo" id="titulo" 
                        label={ t('screens-course-list-titulo') } 
                        component={ renderFieldTwoColumns } 
                        icon="text_fields" 
                        key="titulo" 
                    />
                    
                    <Field name="cursos_tipo_id" id="cursos_tipo_id" 
                        label={ t('screens-course-list-tipo') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="bookmark" 
                        key="cursos_tipo_id" 
                    >
                        <option value="">{ t('form-select-choose-one') }</option>
                        {
                            courseTypeList.map(courseType => (
                                <option value={ courseType.id } key={ courseType.id }>{ courseType.nombre }</option>
                            ))
                        }
                    </Field>                    
                    <Field name="formadores_homologado_id" id="formadores_homologado_id" 
                            label={ t('screens-course-list-formador') } 
                            component={ renderSelectFieldTwoColumns } 
                            icon="person_pin_circle" 
                            key="formadores_homologado_id"
                            visible={ true }
                        >
                            <option value="">{ t('form-select-choose-one') }</option>
                            {
                                formadorList.map(formador => (
                                    <option value={ formador.id } key={ formador.id }>{ `${formador.alumno.apellidos}, ${formador.alumno.nombre}` }</option>
                                ))
                            }
                    </Field>                    
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('search-form-button-title') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('reset-form-button-title') }
                            disabled={ submitting }
                            onClick={ handleReset }
                        />
                    </div>
                </div>
            </form>
        );
    };

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <th>{ t('screens-list-acciones') }</th>
                        <th>{ t('screens-course-selector-curso') }</th>
                        <th>{ t('screens-course-list-tipo') }</th>
                        <th>{ t('screens-course-list-formador') }</th>
                        <th>{ t('screens-course-list-fecha_inicio') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.id }>
                                <td>
                                    <ActionButton icon="arrow_forward"
                                        tooltip={ t('screens-list-acciones-select') } 
                                        extraClass="btn-floating btn-small waves-effect waves-light pink darken-4 ml-3" 
                                        onClick={ () => onSelect(item) } />
                                </td>
                                <td>{ item.titulo }</td>
                                <td>{ item.cursos_tipo.nombre }</td>
                                { (item.formadores_homologado !=null ) ?
                                   <td>{ item.formadores_homologado.alumno.label }</td>  : <td>{ 'Sin definir' }</td>}
                                <td>{ dateToDatepicker(item.fecha_inicio) }</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>
            <PageTitle title={ t('screens-course-selector-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <Spacer height="5" />
        </PageContainer>
    );
};

const validate = (values) => ({});

export default reduxForm({
    form: 'courseSelectorForm',
    validate: validate,
    destroyOnUnmount: true
})(ScreensCourseSelector);