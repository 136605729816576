import React from 'react';
import { Link } from 'react-router-dom';

const HeaderLogo = () => {
    return (
        <ul className="left">
            <li>
                <h1 className="logo-wrapper">
                    <Link to="/" className="brand-logo darken-1">
                        <img 
                            src="/assets/images/logo-bn.png" 
                            alt="JARDM - Área Privada" />
                        <span 
                            className="logo-text hide-on-med-and-down">
                            GEP Services | ERP
                        </span>
                    </Link>
                </h1>
            </li>
        </ul>
    );
};

export default HeaderLogo;